<!--
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-06 15:39:33
 * @listEditTime: Do not edit
-->
<template>

  <el-dialog  title="编辑模块" :append-to-body="true" :lock-scroll="false" modal :close-on-click-modal="false"  style="z-index: 9999999;"
    :visible.sync="dataimgeditDialogType" v-if="dataimgeditDialogType"   >
    <el-form  label-width="120px" :model="formListMode" ref="dialogFromRef" style="margin-left: 10%;" :rules="rules">
      <!-- <el-form-item label="模块标识" style="width: 40%;" prop="ModuleId" >
        <el-input v-model="formListMode.ModuleId"></el-input>
      </el-form-item> -->
      <el-form-item label="模块分类" style="width: 42%;" prop="moduleClassification">
        <div class="selectBox">
          <el-select v-model="formListMode.moduleClassification" placeholder="请选择">
            <el-option v-for="item in classifcationList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
          <el-tooltip class="item" effect="dark" content="外链链接，引入第三方系统地址，页面需要配\n 置显示封面图，点击后浏览器跳转打开。" placement="right">
            <span style="margin-left:10px ;">
              <img
                src="https://files.axshare.com/gsc/U5SCWV/d3/d5/ef/d3d5ef8530e54dd49ea2e772faf7652e/images/图片文字类-模块编辑页面/u39.svg?pageId=8c79d6bf-7cec-4721-87d5-d243bf6994da"
                alt="">
            </span>
          </el-tooltip>
        </div>

      </el-form-item>
      <el-form-item label="数据来源" style="width: 42%;" prop="datafromListid" >
        <div class="selectBox">
          <el-select v-model="formListMode.datafromListid" placeholder="请选择">
            <el-option v-for="item in datafromList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
          <el-tooltip class="item" effect="dark" content="自带编辑器，编辑文章。" placement="right">
            <span style="margin-left:10px ;">
              <img
                src="https://files.axshare.com/gsc/U5SCWV/d3/d5/ef/d3d5ef8530e54dd49ea2e772faf7652e/images/图片文字类-模块编辑页面/u39.svg?pageId=8c79d6bf-7cec-4721-87d5-d243bf6994da"
                alt="">
            </span>
          </el-tooltip>
        </div>

      </el-form-item >
      <!-- <el-form-item label="是否创建分类">
        <el-radio v-model="formListMode.radio" @input="inputRadioFunc" label="1">是</el-radio>
        <el-radio v-model="formListMode.radio" @input="inputRadioFunc" label="2">否</el-radio>

      </el-form-item> -->
      <el-form-item label="创建分类名称" style="width: 70%;"  prop="createName" v-if="formListMode.datafromListid == 2?true:false" >
        <el-input v-model="formListMode.createName"></el-input>
      </el-form-item>
      <!-- <el-form-item label="指定ID" style="width: 70%;" v-if="formListMode.datafromListid == 2?false:true"  prop="Specify the ID">
        <el-input v-model="formListMode.specifyTheId"></el-input>
      </el-form-item> -->
      <el-form-item label="所属板块" style="width: 40%;" prop="radioModel"   v-if="formListMode.datafromListid == 2?false:true" >
        <div style="display: flex; flex-direction: column;border: 1px solid black;padding: 10px;"  >
        <el-radio v-model="radioModel" :label="item.value" v-for="(item,index) in radioList"  :key="index" style="margin-top: 20px;">{{ item.name }}</el-radio>
      </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dataimgeditDialogType = false">取 消</el-button>
      <el-button type="primary" @click="submitForm('dialogFromRef')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {mapActions} from 'vuex'
import {mapState} from 'vuex';
import {createNewsCategory} from '@/api/news/index'
export default {
  props: {

  },
  mounted(){
    this.init()
  },
  data() {
    return {
      // form表单修改数据
      formListMode: {
        modulesId: "",
        dataCardIndex: "",
        moduleClassification: "1",
        address: "",
        userName: "",
        passWord: "",
        specifyTheId: "",
        datafromListid:"1",
        ModuleId: "",
        createName:""
      },
      radioModel:"20", // 选择的data数据
      // radio数据
      radioList:[

        {name:"农牧动态",value:"20"},
        {name:"工作安排 流程 报告 成效成绩成果",value:"2"},
        {name:"工作简报",value:"21"},
        {name:"一张图",value:"24"},
        {name:"舆情监测",value:"23"},
        {name:"政策解读",value:"22"},
        {name:"政治理论学习",value:"1"},
        {name:"党建工作",value:"3"},


      ],
      radioType:{
        24:"3",  //一张图
        20:"2",
        2:"2",
        21:"2",
        23:"2",
        22:"2",
        1:"2",
        3:"2",
      },
      rules:{
        createName: [
            { required: true, message: '请输入创建分类名称', trigger: 'blur' },
      ],
      },
      dataimgeditDialogType: false,
      classifcationList: [
        {
          value: '1',
         label: '文章多篇'
        },
        //  {
        //   value: '2',
        //   label: '文章多篇'
        // },

      ],


      // 下拉菜单list
      datafromList: [
        {
          value: '1',
          label: '内置数据板块'
        }, {
          value: '2',
          label: '在线列表编辑器'
        },

      ],


    }
  },

  methods:{

    init(){
      if(this.$parent.dragItemName?.name == "单篇文章"){
        this.formListMode.moduleClassification = "1"
      }
      if(this.$parent.dragItemName?.name == "图文多篇"){
        this.formListMode.moduleClassification = '2'
      }
    },




    /**
     * @Author: YangXin
     * @Description: 校验form表单
     * @Date: 2024-08-14 10:19:10
     * @param {*} formName
     * @return {*}
     */
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.formFubmitFunc()
          } else {
            return false;
          }
        });
      },


    /**
     * @Author: YangXin
     * @Description: 图文编辑输入
     * @Date: 2024-08-07 11:10:37
     * @return {*}
     */


    formFubmitFunc:function(){

      let cardList = this.currentDepartment[this.$parent.formListMode.dataCardIndex]
      let cardChild = cardList.children[this.$parent.formListMode.cardChildIndex]
      this.dataimgeditDialogType = false

      // if(this.formListMode.datafromListid !== "2"){
        if(cardChild.children){

          // if(cardChild.children[0].type =="videoItem"){
          //   cardChild.children[0].vodeoList.push({url:""})
          // }
        }else{
          if(this.$parent.dragItemName?.name == "图文列表") {
            debugger
            if(this.formListMode.datafromListid == 2){
              createNewsCategory({name:this.formListMode.createName}).then(res=>{
                this.imgTextList.dataSources.params = {
                  id:res.id,
                  name:res.name
                }
                this.imgTextList.mapping={
                  title:'title',
                  src:'images',
                  date:'created_at',
                  source:'author'
                }
                this.$parent.$parent.$refs.dynamicRefs.OnlineEditor({
                                          dataCardIndex: this.$parent.formListMode.dataCardIndex,
                                           cardTwoIndex: this.$parent.formListMode.cardChildIndex,id:res.id
                                          })
                cardChild.children= JSON.parse(JSON.stringify([this.imgTextList]))
                this.loadDataSource({name:"category",params:{id:res.id,name:res.name}})

              })
            }else{
              let userDepId=this.userDepartmentId

              this.imgTextList.dataSources.name = "category"
              this.imgTextList.dataSources.params={
                id : this.radioType[this.radioModel],
                column_id:(this.radioModel==24)?"":this.radioModel,
                department_id:userDepId
              }
              this.imgTextList.mapping = {
                title:'title',
                src:'images',
                date:'created_at',
                source:'author'
              }


              this.loadDataSource({name:"category",params:{
                  id : this.radioType[this.radioModel],
                  column_id:(this.radioModel==24)?"":this.radioModel,
                  department_id:userDepId
              }})
              cardChild.children= JSON.parse(JSON.stringify([this.imgTextList]))
            }

          }
          if(this.$parent.dragItemName?.name == "单篇文章") {
            cardChild.children = [{"type":"articleDetails"}]
          }
          if(this.$parent.dragItemName?.name == "图片") {
            cardChild.children = [{"type":"img",src:require("@/assets/images/data/mls.png"),name:"图片"}]
          }
          if(this.$parent.dragItemName?.name == "视频") {
            cardChild.children = [{"type":"videoItem",vodeoList:[{url:""}]}]
          }
          if(this.$parent.dragItemName?.name == "pdf") {
            cardChild.children = [{"type":"pdf-viewer",title:"123131",srcList:["https://nmt.obs.cn-nmhh1.ctyun.cn/media/2024-05-27/090DF514EAC64EEC9F765759205813D6/兽医工作动态（2024年第一期）.pdf"]}]
          }
        }
        this.$refs["dialogFromRef"].resetFields();
      // }else{
      //   this.$parent.imgTextEditType = true
      // }
      // this.currentDepartment
    },
    ...mapActions({
      loadDataSource:'schema/LoadDataSource',
    }),
  },


  computed: {
    ...mapState('user', {
      userId: state => state.userInfo.id,
      userName: state => state.userInfo.name,
      userDepartmentId: state => state.userInfo.department.id,
      userDepartmentName: state => state.userInfo.department.name,
    }),


    currentDepartment() {
      return this.$store.getters["schema/schema"].value
     },

    imgTextList() {
      return this.$store.getters.imgTextList;
    },
  }
}
</script>

<style lang="scss" scoped>
.selectBox {
  display: flex;
  height: 100%;
  width: 100%;
}
</style>
