<template>
  <div>
    <div ref="tabListRef">
      <div class="outBtnStyle">
        <el-button @click="outEditFunc">退出编辑模式</el-button>
        <el-button @click="saveSchema()">保存页面布局</el-button>
      </div>
      <div class="tabsBox">

        <tabEditor class="tabBox" :tabLabel="tabLabelList" ref="tabEditorRefs">
          <template v-slot:1>
            <div class="cardBox">
              <div class="addCard" v-for="(item, index) in startItemList" :key='index+1' @click="cardClickFunc(index)">
                <div class="addCradImg">
                  <img :src="item.img" alt="">
                </div>
                <div>{{ item.name }}</div>
              </div>
            </div>
          </template>
          <template v-slot:2>
            <Vuedraggable :list="cardItemList" :group="{ name: 'cardGroup', pull: 'clone', put: false }"
              @start="startrameFunc" :sort="false" :clone="cloneCardItemFunc"
              style="height: 100%;display: flex;align-items: center;">
              <transition-group class="cardBox">
                <div class="addCard" v-for="(item, index) in cardItemList" :key='index+1' @click="cardClickFunc(index)">
                  <div class="addCradImg">
                    <img :src="item.img" alt="">
                  </div>
                  <div>{{ item.name }}</div>
                </div>
              </transition-group>
            </Vuedraggable>
          </template>
          <template v-slot:3>
            <Vuedraggable :list="frameItemList" @start="startrameFunc" @end="startrameEnd"
              :group="{ name: 'frameFGroup', pull: 'clone', put: false }" :sort="false" :clone="cloneFrameFunc"
              style="height: 100%;display: flex;align-items: center;">
              <transition-group class="cardBox">
                <div class="addCard" v-for="(item,index) in frameItemList" :key='index+1'>
                  <div class="addCradImg">
                    <img :src="item.img" alt="">
                  </div>
                  <div>{{ item.name }}</div>
                </div>
              </transition-group>
            </Vuedraggable>
          </template>
          <template v-slot:4>
            <el-tabs v-model="activeModelName" style="width: 100%;">
              <el-tab-pane label="链接类" name="linkTab" style="width: 100%;">
                <Vuedraggable class="linkBox" :list="linkBtnList" @start="startrameFunc" @end="linkEndFunc"  :move="moveFunc"
                  :group="{ name: 'frameFGroup', pull: 'clone', put: false }" :sort="false"
                  :clone="cloneFrameFunc" style="height: 100%;display: flex;align-items: center;">
                  <transition-group class="cardBox">
                    <div class="addCard" v-for="(item,index) in linkBtnList" :key='index+1'>
                      <el-button>{{ item.name }}</el-button>
                    </div>
                  </transition-group>
                </Vuedraggable>

              </el-tab-pane>
              <el-tab-pane label="图片文章类" name="imgClassTab" style="width: 100%;">
                <Vuedraggable class="linkBox" :list="imgPhotoArticleList" @start="startrameFunc" @end="linkEndFunc"  :move="moveFunc"
                  :group="{ name: 'frameFGroup', pull: 'clone', put: false }" :sort="false" :clone="cloneFrameFunc"
                  style="height: 100%;display: flex;align-items: center;">
                  <transition-group class="cardBox">
                    <div class="addCard" v-for="(item,index) in imgPhotoArticleList" :key='index+1'>
                      <el-button>{{ item.name }}</el-button>
                    </div>
                  </transition-group>
                </Vuedraggable>
              </el-tab-pane>
              <el-tab-pane label="多媒体类型" name="multiMediaTab" style="width: 100%;">
                <Vuedraggable class="linkBox" :list="pictureVideoList" @start="startrameFunc"
                  :group="{ name: 'frameFGroup', pull: 'clone', put: false }" :sort="false" :clone="cloneFrameFunc" :move="moveFunc"
                  @end="linkEndFunc" style="height: 100%;display: flex;align-items: center;">
                  <transition-group class="cardBox">
                    <div class="addCard" v-for="(item,index) in pictureVideoList" :key='index+1'>
                      <el-button>{{ item.name }}</el-button>
                    </div>
                  </transition-group>
                </Vuedraggable>
              </el-tab-pane>
               <el-tab-pane label="空间地图类型" name="multiMapTab" style="width: 100%;">
                <Vuedraggable class="linkBox" :list="mapTypeList" @start="startrameFunc"  :move="moveFunc"
                  :group="{ name: 'frameFGroup', pull: 'clone', put: false }" :sort="false" :clone="cloneFrameFunc"
                  @end="linkEndFunc" style="height: 100%;display: flex;align-items: center;">
                  <transition-group class="cardBox">
                    <div class="addCard" v-for="(item,index) in mapTypeList" :key='index+1'>
                      <el-button>{{ item.name }}</el-button>
                    </div>
                  </transition-group>
                </Vuedraggable>
              </el-tab-pane>
<!--              <el-tab-pane label="空间地图类" name="mapClassTab" style="width: 100%;"></el-tab-pane>-->
            </el-tabs>
          </template>
        </tabEditor>
      </div>
    </div>


    <titleDialog ref="titleDialogRef" v-if="titleDjalogType"></titleDialog>




    <el-dialog  title="编辑模块" :close-on-click-modal="false"  :append-to-body="true" :lock-scroll="false"   modal style="z-index: 9999999;"
      :visible.sync="editDialogType">
      <kj-from :formItem="formItem" ref="ruleForm" :formListMode="formListMode" :rules="rules">
        <template v-slot:inputSlot>
          <el-input v-model="formListMode.modulesId"></el-input>
        </template>
        <template v-slot:selectSlot>
          <el-select v-model="formListMode.moduleClassification" placeholder="请选择">
            <el-option v-for="item in classifcationList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </template>
        <template v-slot:httpInputSlot>
          <el-input v-model="formListMode.address"></el-input>
        </template>
        <template v-slot:loginUserSlot>
          <el-input v-model="formListMode.userName" style="width: 50%;"></el-input>
        </template>
        <template v-slot:loginPassWordSlot>
          <el-input v-model="formListMode.passWord" style="width: 50%;"></el-input>
        </template>
        <template v-slot:uploadImgSlot>
          <el-upload class="upload-demo" drag
            action="" :http-request="onUpload" multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </template>
        <template v-slot:superLinkTitleSolt>
          <el-input v-model="formListMode.superLink" style="width: 50%;"></el-input>
        </template>
        <template v-slot:superiorImgSlot>
          <el-input v-model="formListMode.superior" style="width: 50%;"></el-input>
        </template>

      </kj-from>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogType = false">取 消</el-button>
        <el-button type="primary" @click="formFubmitFunc">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="编辑地图" :append-to-body="true" :lock-scroll="false" modal style="z-index: 9999999;"
      :visible.sync="editMapDialogType">
      <kj-from :formItem="mapDataItem" ref="ruleForm" :formListMode="mapFormMode" :rules="rules">

        <template v-slot:selectSlot>
          <el-select v-model="mapFormMode.mapDataSelect" placeholder="请选择">
            <el-option v-for="item in mapDataSelectList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </template>


      </kj-from>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editMapDialogType = false">取 消</el-button>
        <el-button type="primary" @click="formMapSelectSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <dialogForm ref="dialogFormRef"></dialogForm>
    <imgTextEdit :eidtDialogType="true" v-if="imgTextEditType" :cardIndex="cardItemObject"></imgTextEdit>
    <imagesEdit :imagesEidtDialogType="true" v-if="imagesEidtDialogType" :cardIndex="cardItemObject"></imagesEdit>

  </div>

</template>

<script>
import dynamicRenderingEngine from "@/views/data/dynamicRenderingEngine.vue";
import dialogForm from './components/dialogForm/dialogForm.vue'; // 添加dialogForm组件
import imgTextEdit from './components/imgTextEdit/imgTextEdit.vue'; // 添加dialogForm组件
import imagesEdit from './components/imagesEdit/imagesEdit.vue'; // 添加dialogForm组件
import { uploadImage } from '@/api/common/upload'

import titleDialog from "./components/titleDialog/titleDialog.vue"
import Vuedraggable from 'vuedraggable' // 添加拖拽组件
import tabEditor from "@/components/kjTab/tabEditor.vue"
import KjFrom from '@/components/kjFrom/kjfrom.vue';
import { mapActions } from "vuex";
import Image from '@/components/upload/image.vue';



export default {
  components: {
    dynamicRenderingEngine,
    Vuedraggable, // 拖拽组件,
    KjFrom,
    dialogForm,
    imgTextEdit,
    titleDialog,
    tabEditor,
    imagesEdit,
    Image
  },
  data() {
    return {
      // 导向数组
      startItemList: [
        { id: 1, img: require("@/assets/images/pageEditImg/tianjiakapian.png"), name: "添加卡片" },
        { id: 2, img: require("@/assets/images/pageEditImg/tianjiakuangjia.png"), name: "添加框架" },
        { id: 3, img: require("@/assets/images/pageEditImg/tianjiamokuan.png"), name: "添加模块" }
      ],
      // 卡片边框数组
      cardItemList: [
        { id: 1, img: require("@/assets/images/pageEditImg/zhuangshibiankuang.png"), name: "有边框" },
        // { id: 2, img: require("@/assets/images/pageEditImg/wubiankuang.png"), name: "无边框" },
      ],
      // 框架拖拽数组
      frameItemList: [
        { id: 2, img: require("@/assets/images/pageEditImg/wubiankuang.png"), name: "固定框架" },
        { id: 1, img: require("@/assets/images/pageEditImg/zhuangshibiankuang.png"), name: "100%框架" },
        // { id: 3, img: require("@/assets/images/pageEditImg/wubiankuang.png"), name: "tabs框架" },
      ],
      // 链接数组
      linkBtnList: [
        { id: 1, name: "内嵌网页" },
        { id: 2, name: "跳转网页" },
      ],

      imgPhotoArticleList: [
        { id: 1, name: "图文列表" },
        // { id: 2, name: "单篇文章" },
      ],

      pictureVideoList: [
        { id: 1, name: "图片" },
        { id: 2, name: "视频" },
        { id: 2, name: "pdf" },

      ],

      mapTypeList: [
        { id: 1, name: "地图" },
      ],
      editMapDialogType: false,

      mapDataItem: [
        {
          prop: "mapDataSelect", label: "地图数据选择", slotName: "selectSlot", isType: true,
        }
      ],
      mapFormMode: {
        mapDataSelect: ''
      },
      mapDataSelectList: [
        {
          value: 'scztResultData',
          label: '市场主体',

        }
        // , {
        //   value: '2',
        //   label:  '全混日粮',
        // },

      ],
      mapData: {

      },

      formItem: [
        // { prop: "modulesId", label: "模块标识", slotName: "inputSlot", isType: true },
        // { prop: "moduleClassification", label: "模块分类", slotName: "selectSlot", isType: true },
        { prop: "address", label: "目标URL地址", slotName: "httpInputSlot", isType: true },
        // { prop: "loginUserProp", label: "登录用户名", slotName: "loginUserSlot", isType: false },
        // { prop: "loginPassWordProp", label: "密码", slotName: "loginPassWordSlot", isType: false },
        { prop: "uploadImgSlotProp", label: "上传", slotName: "uploadImgSlot", isType: false },
        // { prop: "superLinkTitleProp", label: "超链接标题", slotName: "superLinkTitleSolt", isType: false },
        // { prop: "superior", label: "上级分类", slotName: "superiorImgSlot", isType: false },
      ],

      // form表单修改数据
      formListMode: {
        modulesId: "",
        dataCardIndex: "",
        moduleClassification: "1",
        address: "",
        userName: "",
        passWord: "",
        superLink: "",
        superior: "",
        formTypeName: "",
        cardChildIndex: ""
      },

      imageUrl: "", // 上传图片地址

      // 表单校验
      rules: {
        address: [
          { validator: (rule, value, callback)=>{
            console.log(value.indexOf("https://"))
            if (value === '') {
              callback(new Error('请输入域名地址'));
            } else if (value.indexOf("http") == -1) {
              callback(new Error('请开头输入https://或者http://'));
            } else {
              callback();
            }
          }, trigger: 'blur' }
        ],
      },

      linkWriting: false, // 文字外链输入文本
      linkLoginType: false, // 登陆外链
      imgLinkType: false,
      dialogTableVisible: false,
      draggableType: false, // 编辑模式拖拽按钮 开启/关闭
      editDialogType: false, // link拖拽弹窗编辑 开启/关闭
      imgTextEditType: false, // 编辑器弹窗
      dragItemName: {}, // 当前拖动的名称
      titleDjalogType: false,
      activeModelName: "linkTab",
      cardItemObject: "", // 在线编辑器
      imagesEidtDialogType:false, // 图片编辑器

      classifcationList: [
        {
          value: '1',
          label: '内嵌网页'
        }, {
          value: '2',
          label: '跳转网页'
        },

      ],


      pdfUrls: ["/mock/yzt/内蒙古自治区种业布局图放大版.pdf"],
      pdfCurrentDepartment: {},
      pdfCurrentTitle: "",

      draggableTop: "0px",
      tabLabelList: [{ label: "开始", name: "1", }, { label: "卡片", name: "2", }, { label: "框架", name: "3", }, { label: "模块", name: "4" }]
    }
  },
  methods: {

    /**
     * @Author: YangXin
     * @Description: 退出编辑模式
     * @Date: 2024-08-06 14:28:39
     * @return {*}
     */
    outEditFunc: function () {
      this.$parent.$refs["main-body"].style.marginTop = "0px"
       this.$parent.$refs["engineBoxRef"].style.height = "90vh"

      this.setGlobalType(false)
      this.$parent.$data.draggableType = false

    },
    saveSchema(){
      this.$emit("saveEditor");
    },
    /**
     * @Author: YangXin
     * @Description: 上传图片时候回调数据
     * @Date: 2024-08-06 17:26:14
     * @param {*} response
     * @param {*} file
     * @param {*} fileList
     * @return {*}
     */
    imgSuccessFunc(response, file, fileList) {
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(response, file, fileList)

    },
    /**
     * @Author: YangXin
     * @Description: 处理拖拽组件拷贝
     * @Date: 2024-08-02 10:03:20
     * @return {*}
     */
    cloneCardItemFunc: function (item,index){
      let typeObject = {
        name: "",
        children: [],
        type: "card",
        dataCardStyle: "width:2%;"
      }
      switch (item.name) {
        case "有边框":
          typeObject.type = "card"
          typeObject.dataCardStyle = ""
          break;
        case "无边框":
          typeObject.type = "bezel_less"
          break;
        default:
          dom.type = ""
      }
      let dataJson = JSON.parse(JSON.stringify(typeObject))
      // this.$parent.contentChanged();
      return dataJson
    },

    /**
     * @Author: YangXin
     * @Description: 复制子集数据
     * @Date: 2024-08-03 14:44:33
     * @return {*}
     */
    cloneFrameFunc: function (item,index){
      this.dragItemName = item
      console.log(this.dragItemName)

      switch (item.name) {
        case "内嵌网页":
          this.ifTypeFunc("内嵌网页")
          return
        case "跳转网页":
          this.ifTypeFunc("跳转网页")
          return
        case "文字外链":
          this.ifTypeFunc("文字外链")
          return
        case "图文列表":
          return
        case "单篇文章":
          return
        default:
          return
      }
    },
    /**
     * @Author: YangXin
     * @Description:form表单组件状态切换
     * @Date: 2024-08-05 18:55:33
     * @param {*} name
     * @return {*}
     */
    ifTypeFunc: function (name) {
      this.formListMode.formTypeName = name
      this.formItem.map((item, index) => {
        if (index > 0) {
          // if (name == "内嵌网页" && (index == 3 || index == 4)) {
          //   return item.isType = true
          // }
          if (name == "跳转网页" && (index == 1)) {
            return item.isType = true
          }else{
           return item.isType = false

          }
          // if (name == "文字外链" && (index == 6 || index == 7)) {
          //   return item.isType = true
          // }
        }
      })
    },
    /**
     * @Author: YangXin
     * @Description: 开始拖拽
     * @Date: 2024-08-03 13:48:56
     * @return {*}
     */
    startrameFunc: function (item,index){
      if (this.$refs.tabEditorRefs.activeName == "2") {
        this.$parent.$refs.dynamicRefs.engineGroup.put = true
        this.$parent.$refs.dynamicRefs.engineChildrenGroup.put = false
        this.$parent.$refs.dynamicRefs.blockChildrenGroup.put = false

      }
      if (this.$refs.tabEditorRefs.activeName == "3") {
        this.$parent.$refs.dynamicRefs.engineGroup.put = false
        this.$parent.$refs.dynamicRefs.engineChildrenGroup.put = true
        this.$parent.$refs.dynamicRefs.blockChildrenGroup.put = false

      }
      if (this.$refs.tabEditorRefs.activeName == "4") {
        this.$parent.$refs.dynamicRefs.engineGroup.put = false
        this.$parent.$refs.dynamicRefs.engineChildrenGroup.put = false
        this.$parent.$refs.dynamicRefs.blockChildrenGroup.put = true
      }
    },

    moveFunc(val){
      let dataId = val.to.__vue__.$attrs.dataCardIndex
      let cardChildId = val.to.__vue__.$attrs.cardChildIndex
      let data = this.$parent.currentDepartment[dataId].children[cardChildId].children
      if(data){
        return false
      }else{
        return true
      }

    },


    /**
     * @Author: YangXin
     * @Description: 上传图片
     * @Date: 2024-08-13 16:32:47
     * @param {*} data
     * @return {*}
     */
    onUpload:function(data) {
        uploadImage(data.file, progress => {
            if (progress.total > 0) {
            progress.percent = (progress.loaded / progress.total) * 100
            }
            data.onProgress(progress)
        }).then(res => {
            this.imageUrl = res.image_url
        })
    },
    /**
     * @Author: YangXin
     * @Description: 添加link拖拽结束事件
     * @Date: 2024-08-05 14:59:18
     * @return {*}
     */
    linkEndFunc: function (e) {
      //拖拽弹框
      this.formListMode.dataCardIndex = e.to.__vue__.$attrs.dataCardIndex
      this.formListMode.cardChildIndex = e.to.__vue__.$attrs.cardChildIndex

      if (e.pullMode && this.activeModelName == "linkTab") {
        this.editDialogType = true
      }
      if (e.pullMode && this.activeModelName == "imgClassTab") {
        this.$refs.dialogFormRef.dataimgeditDialogType = true

      }
      if (e.pullMode && this.activeModelName == "multiMediaTab") {
        this.cardItemObject = {dataCardIndex: this.formListMode.dataCardIndex, cardTwoIndex: this.formListMode.cardChildIndex }

        // if(){
          this.imagesEidtDialogType = true

        // }
        // this.$refs.dialogFormRef.dataimgeditDialogType = true

      }
      if (e.pullMode && this.activeModelName == "multiMapTab") {
        this.mapFormMode.dataCardIndex = e.to.__vue__.$attrs.dataCardIndex
        this.mapFormMode.cardChildIndex = e.to.__vue__.$attrs.cardChildIndex
        this.editMapDialogType = true
      }
    },

    /**
     * @Author: YangXin
     * @Description: 监听link按钮移动
     * @Date: 2024-08-03 13:48:56
     * @return {*}
     */
    dragLinkFunc: function (e) {
      console.log(e)
    },

    /**
     * @Author: YangXin
     * @Description: 结束拖拽
     * @Date: 2024-08-03 13:58:55
     * @return {*}
     */
    startrameEnd: function () {
      this.$parent.$refs.dynamicRefs.engineGroup.put = true
      this.$parent.$refs.dynamicRefs.engineChildrenGroup.put = false
    },

    /**
     * @Author: YangXin
     * @Description: 自定义拖拽限制
     * @Date: 2024-08-03 11:47:10
     * @return {*}
     */
    onMoveframeFunc: function (e, originalEvent) {
      if (e.relatedContext.list.length <= 0) {
        return true
      }
      return false
    },
    /**
     * @Author: YangXin
     * @Description: 切换流程卡片
     * @Date: 2024-08-03 10:46:22
     * @param {*} index
     * @return {*}
     */
    cardClickFunc: function (index) {
      this.$refs.tabEditorRefs.activeName = String(index + 2)
    },


    /**
     * @Author: YangXin
     * @Description: //生成html
     * @Date: 2024-08-02 13:37:21
     * @return {*}
     */
    generateCode: function () {
      const { type } = this.generateConf
      this.AssembleFormData()
      const script = vueScript(makeUpJs(this.formData, type))
      const html = vueTemplate(makeUpHtml(this.formData, type))
      const css = cssStyle(makeUpCss(this.formData))
      return beautifier.html(html + script + css, beautifierConf.html)
    },



    /**
     * @Author: YangXin
     * @Description: 提交表单控制添加链接
     * @Date: 2024-08-06 08:59:54
     * @return {*}
     */
    formFubmitFunc: function () {
      this.$refs.ruleForm.submitForm("ruleForm").then((res) => {
        if (res.isType == true) {

          this.editDialogType = false
          // this.currentDepartment = ""
          let dataList = this.$parent.currentDepartment[this.formListMode.dataCardIndex]

          if (dataList.children[this.formListMode.cardChildIndex].children) {

            if (this.formListMode.formTypeName == "内嵌网页") {
              dataList.children[this.formListMode.cardChildIndex].children[0].src = this.formListMode.address

            }
            if (this.formListMode.formTypeName == "跳转网页") {
              dataList.children[this.formListMode.cardChildIndex].children[0].src = this.imageUrl
              dataList.children[this.formListMode.cardChildIndex].children[0].clickUrl = this.formListMode.address
            }
          } else {
            if (this.formListMode.formTypeName == "内嵌网页") {
              dataList.children[this.formListMode.cardChildIndex].children = [{ type: "web-view", src: this.formListMode.address }]
            }
            if (this.formListMode.formTypeName == "跳转网页") {
              dataList.children[this.formListMode.cardChildIndex].children = [{ type: "JumpImg", src: this.imageUrl, clickUrl: this.formListMode.address }]
            }
          }

          this.$refs.ruleForm.resetForm("ruleForm");


        }
      })
    },

    /**
     * @Author:
     * @Description: 地图弹窗
     * @Date: 2024-08-11 15:09:33
     * @return {*}
     */
    formMapSelectSubmit: function () {
      this.$refs.ruleForm.submitForm("ruleForm").then((res) => {
        if (res.isType == true) {
          this.editMapDialogType = false
          let dataName = this.mapFormMode.mapDataSelect
          let dataList = this.$parent.currentDepartment[this.mapFormMode.dataCardIndex]
          dataList.children[this.formListMode.cardChildIndex].children = [
            { type: "map-marker-cluster", dataSources: { "name": dataName, "params": {} } }
          ]
          this.loadDataSource({ name: dataName })
          this.$refs.ruleForm.resetForm("ruleForm");
        }
      })
    },
    ...mapActions({
      loadDataSource: 'schema/LoadDataSource',
      setGlobalType:"setGlobalType"
    }),
  }
}
</script>


<style scoped lang="scss">
.outBtnStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 15%;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 10002;
}


.tabsBox {
  height: 100%;
  width: 100%;
  position: absolute;

  .startBox,
  .cardBox {
    height: 100%;
    display: flex;
    align-items: center;
    color: #000;

    // justify-content: space-around;
    .addCard {
      width: 122px;
      height: 100%;
      margin-right: 22px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      .addCradImg {

        width: 90%;
        background-size: 100% 100%;
      }

    }


  }

  .linkBox {
    display: flex;
  }

}

.el-tab-pane {
  height: 100%;
  width: 100%;
}



.dialog {
  width: 1178px;
  height: 666px;
  background: linear-gradient(180deg, #004146 0%, #000A25 10%, rgba(0, 10, 37, 0) 100%);
  border: 1px solid #66FFFF;
  backdrop-filter: blur(13px);
  color: #FFFFFF;

  .el-dialog__title,
  .el-dialog__body {
    color: #FFFFFF;
  }
}

.el-select-dropdown {
  z-index: 9999999 !important;
}
</style>
