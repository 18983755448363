<template>
  <div style="height: 100%" @wheel="handleWheel2">




    <div class="graphic" @mouseleave="handleMouseLeave" >
      <div style="font-size: 12px;color: silver;padding-left: 10rpx;text-align: center">
        累计 {{data.length}} 篇文章
      </div>
      <div class="graphic-list" v-for="(item, index) in data" :key="index" @click="seeView(item)"
        @mouseenter="eidtBtnDisplay(index)">

<!--        {{item[mapping['src']]}}-->
        <!-- <div class="topMenu" v-show="index === topMenuType">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <el-button>编辑</el-button>

            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-plus">数据编辑</el-dropdown-item>
              <el-dropdown-item  @click="delBlockItemFunc">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div> -->
        <div class="graphic-list-top">
          <div class="graphic-list-top-title">
            <p v-if="item[mapping['title']]">{{ item[mapping['title']] }}</p>
          </div>
          <div class="graphic-list-image" v-if="item[mapping['src']]">
            <img :src="getImageSrc(item[mapping['src']])"   />
          </div>
        </div>
        <div class="graphic-list-footer" style="font-size: 12px">
          <div v-if="item[mapping['source']]">
            <p>{{ item[mapping['source']] }}</p>
          </div>
          <div v-if="item[mapping['date']]">
            <p>发布时间：{{ sliceDate(item[mapping['date']].slice(0, 10)) }}</p>
          </div>
        </div>
      </div>

    </div>

    <el-dialog :close-on-click-modal="false" append-to-body style="z-index: 999999;" title="" :visible.sync="dialogTableVisible">
      <h2 style="text-align: center">
        {{ itemObj[mapping['title']] }}
      </h2>
      <br />
      <span v-if="itemObj[mapping['date']]">
        <span>发布时间：{{ sliceDate(itemObj[mapping['date']].slice(0, 10)) }}</span>
      </span>
      <span v-if="itemObj[mapping['source']]" style="margin-left: 30rpx">
        责任部门：{{ itemObj[mapping['source']] }}
      </span>
      <br />
        <div  style="text-align: center" v-for="(item, index) in itemObj[mapping['src']]">

          <img style="width: 50%" :src="item"  />
          <br />
        </div>
      <br />
      <div v-html="textContent" class="centent simditor-body">

      </div>

    </el-dialog>

  </div>
</template>

<script>
export default {
  data: () => ({
    dialogTableVisible: false,
    textContent: "",
    itemObj: {},
    topMenuType: ""
  }),
  props: {
    data: {
      type: Array,
      default: () => ([
        {
          title: "默认标题",
          src: "默认图片路径",
          date: "默认日期",
          source: "默认来源"
        }
      ])
    },
    mapping: {
      type: Object,
      default: () => ({ title: 'title', src: 'src', date: 'date', source: 'source' })
    }
  },
  watch:{
    // data(newVal, oldVal){
    //     this.data=newVal;
    // }
  },
  created() {
    let that = this;
    this.$watch('data', (newVal, oldVal) => {
      // console.log('Data changed:', newVal, 'from', oldVal);
      setTimeout(() => {
        // console.log(this.data)



      }, 2000);
    }, { immediate: true, deep: true });
  },
  methods: {
    getImageSrc(src) {
      if (Array.isArray(src)) {
        return src.length > 0 ? src[0] : '';
      }
      return src;
    },
    handleWheel2(e){
       e.stopPropagation();
    },
    seeView(item) {
      if (item.href||item.url) {
        window.open(item.href||item.url);

        return
      }

      if (item.content) {
        this.textContent = item.content
        this.itemObj = item;
        this.dialogTableVisible = true;
        return;
      }

      this.$emit("onClick", item)
    },

    /**
     * @Author: YangXin
     * @Description: 添加edit按钮的显示隐藏
     * @Date: 2024-08-08 15:09:07
     * @param {*} index
     * @return {*}
     */
    eidtBtnDisplay: function (index) {
      this.topMenuType = index
    },
    /**
     * @Author: YangXin
     * @Description: 移出事件
     * @Date: 2024-08-08 15:13:26
     * @return {*}
     */
    handleMouseLeave() {
      this.topMenuType = ""
    },


    sliceDate(e) {
      if (e.length > 10) {
        // 如果长度大于10，则截取前10个字符
        let result = e.slice(0, 10);
        return result
      } else {
        return e
      }
    }
  }
};
</script>

<style scoped lang="scss">
.graphic {
  height: calc(100vh - 260px);
  /* 根据需要调整高度 */
  overflow-y: auto;
  padding: 2px;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #014E5E #FFFFFF00;
  /* For Firefox */

  /* For WebKit browsers */
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #FFFFFF;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #014E5E;
    border-radius: 2px;
    border: 1px solid #FFFFFF;
    /* Adjust the border color if needed */
  }
}

.topMenu {
  position: absolute;
  right: 0;

  z-index: 2;
}

.graphic-list {

  position: relative;
  padding: 16px;

  margin-bottom: 10px;
  border-bottom: 1px solid #014E5E;

  &:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    opacity: 0.1;
    z-index: 0;
  }

  &-top,
  &-footer {
    position: relative;
    z-index: 1;
  }

  &-top {
    display: flex;

    &-title {
      flex: 1;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 15px;
      color: #FFFFFF;

      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;

        line-height: 28px;
      }
    }
  }

  &-image {
    width: 120px;
    margin-left: 8px;

    img {
      width: 120px;
      border-radius: 8px;
      max-height: 70px;
    }
  }

  &-footer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #B3B7BF;
    line-height: 17px;
  }
}

.centent img {
  width: 100% !important;
}





.simditor-body,
.topic-body {
  font-size: 15px;
  line-height: 1.3;
  overflow: hidden;
  line-height: 1.6;
  word-wrap: break-word;

  a {
    background: transparent;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  ol li {
    margin: 8px 0;
  }

  pre[class*=language-] {
    margin: 1.2em 0 !important;
  }

  strong {
    font-weight: bold;
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  img {
    border: 0;
  }

  hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
  }

  hr {
    height: 0;
    margin: 15px 0;
    overflow: hidden;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #ddd;
  }

  hr:before,
  hr:after {
    display: table;
    content: " ";
  }

  hr:after {
    clear: both;
  }

  blockquote {
    margin: 0;
  }

  ul,
  ol {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  ol ol {
    list-style-type: lower-roman;
  }

  dd {
    margin-left: 0;
  }

  code,
  pre {
    font-family: monaco, Consolas, "Liberation Mono", Menlo, Courier, monospace;
    font-size: 1em;
  }

  pre {
    margin-top: 0;
    margin-bottom: 0;
    overflow: auto;
  }

  .topic-body>*:first-child {
    margin-top: 0 !important;
  }

  .topic-body>*:last-child {
    margin-bottom: 0 !important;
  }

  .anchor {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    padding-right: 6px;
    padding-left: 30px;
    margin-left: -30px;
  }

  .anchor:focus {
    outline: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    position: relative;
    margin-top: 1.0em;
    margin-bottom: 16px;
    line-height: 1.4;
  }

  h1 {
    padding-bottom: 0.3em;
    font-size: 2.25em;
    line-height: 1.2;
    border-bottom: 1px solid #eee;
  }

  h2 {
    padding-bottom: 0.3em;
    font-size: 1.3em;
    line-height: 1.225;
    border-bottom: 1px solid #eee;
  }

  h3 {
    font-size: 1.2em;
    line-height: 1.43;
  }

  h4 {
    font-size: 1.1em;
  }

  h5 {
    font-size: 1.0em;
  }

  h6 {
    font-size: 0.9em;
    color: #777;
  }

  p,
  blockquote,
  ul,
  ol,
  dl,
  table,
  pre {
    margin-top: 0;
    margin-bottom: 0px;
    line-height: 30px;
  }

  hr {
    border: 2px dashed #F0F4F6;
    border-bottom: 0px;
    margin: 18px auto;
    width: 50%;
  }

  ul,
  ol {
    padding-left: 2em;
    padding: 10px 20px 10px 30px;
    color: #7d8688;
  }

  ol ol,
  ol ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  li>p {
    margin-top: 6px;
  }

  dl {
    padding: 0;
  }

  dl dt {
    padding: 0;
    margin-top: 6px;
    font-size: 1em;
    font-style: italic;
    font-weight: bold;
  }

  dl dd {
    padding: 0 16px;
    margin-bottom: 16px;
  }

  blockquote {
    font-size: inherit;
    padding: 0 15px;
    color: #777;
    border-left: 4px solid #ddd;
  }

  blockquote>:first-child {
    margin-top: 20px;
  }

  blockquote>:last-child {
    margin-bottom: 20px;
  }

  blockquote {
    margin: 20px 0 !important;
    background-color: #f5f8fc;
    padding: 1rem;
    color: #8796A8;
    border-left: none;
  }

  table {
    display: block;
    width: 100%;
    overflow: auto;
    margin: 25px 0;
  }

  table th {
    font-weight: bold;
  }

  table th,
  table td {
    padding: 6px 13px;
    border: 1px solid #ddd;
  }

  table tr {
    background-color: #fff;
    border-top: 1px solid #ccc;
  }

  table tr:nth-child(2n) {
    background-color: #f8f8f8;
  }

  img {
    max-width: 100%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  img {
    border: 1px solid #ddd;
    box-shadow: 0 0 30px #ccc;
    -moz-box-shadow: 0 0 30px #ccc;
    -webkit-box-shadow: 0 0 30px #ccc;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  code {
    background: rgba(90, 87, 87, 0);
    margin: 5px;
    color: #858080;
    border-radius: 4px;
    background-color: #f9fafa;
    border: 1px solid #e4e4e4;
    max-width: 740px;
    overflow-x: auto;
    font-size: .9em;
    padding: 1px 2px 1px;
  }

  code:before,
  code:after {
    letter-spacing: -0.2em;
    content: "\00a0";
  }

  pre>code {
    padding: 0;
    margin: 0;
    font-size: 100%;
    white-space: pre;
    background: transparent;
    border: 0;
  }

  .highlight {
    margin-bottom: 16px;
  }

  .highlight pre,
  pre {
    padding: 14px;
    overflow: auto;
    line-height: 1.45;
    // background-color: #4e4e4e;
    border-radius: 3px;
    color: inherit;
    border: none;
  }

  .highlight pre {
    margin-bottom: 0;
  }

  pre {
    word-wrap: normal;
  }

  pre code {
    display: block;
    padding: 0;
    margin: 0;
    overflow: initial;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
  }

  pre code:before,
  pre code:after {
    content: normal;
  }
}
</style>
